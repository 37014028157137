import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor() {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request && request.url && request.url.indexOf("/api/Home/Index") !== -1 && request.method === "GET") {

    } else {
      let user:any = sessionStorage.getItem('userData');
      let userdata = JSON.parse(user);
      if (userdata && userdata.authToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${userdata.authToken}`,
            _client_Ip: '192.3.45.74'
          }
        });
      }
    }
    return next.handle(request);
  }
}

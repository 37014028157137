<div class="login-section d-flex align-items-center justify-content-center">
    <div class="error-card">
        <div class="text-center">
            <img src="../../assets/images/error.png">
            <p class="error404">404</p>
            <p class="errorText">Page not found!!</p>
            <p class="mb-4">The page you are looking for doesn't exist or an other error occurred.</p>
            <!-- <p>Go Back</p> -->
            <button mat-raised-button color="primary" class="ms-1 search" (click)="back()">Go Back</button>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { API_PATH } from '../utility/api-constant';
import { ConfigAService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // api_path = API_PATH;
  api_path;
  public recordsPerPage = 10;
  numberOnly: any;

  getHomeDetails: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private _configService: ConfigAService
  ) {
    this._configService.setEnvirment(this._configService.environment)
    this.api_path = this._configService.API_PATH;
  }


  public isInteger(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.numberOnly = true;
      return false;
    }
    this.numberOnly = false;
    return true;
  }

  getMenuList(request: any): Observable<any> {
    return this.http.post(this.api_path.GetMenuAccess, request)
  }

  logout() {
    return this.http.get(this.api_path.Logout, {})
  }

  // //Location DDL
  // Loction_DDL(): Observable<any> {
  //   return this.http.post(this.api_path.LocationDDL, {})
  // }
  // Template_DDL() : Observable<any>{
  //   return this.http.post(this.api_path.EmailTemplateType_ForDDl, {})
  // }
  //SchoolTypeDDl
  GetSchoolTypeList_DDL(): Observable<any> {
    return this.http.post(this.api_path.SchoolType_SelForDDL, {})
  }
  GetSchoolCategoryList_DDL(): Observable<any> {
    return this.http.post(this.api_path.SchooCategory_SelectForDDl, {})
  }
  //Location DDL
  Loction_DDL(): Observable<any> {
    return this.http.post(this.api_path.LocationDDL, {})
  }
  Template_DDL(): Observable<any> {
    return this.http.post(this.api_path.EmailTemplateType_ForDDl, {})
  }
  getCountryDDL(): Observable<any> {
    return this.http.post(this.api_path.getCountryDDL, {})
  }
  UM_Page_SelForDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.UM_Page_SelForDDL, request)
  }

  // School Location DDL
  schoolLoction_DDL(): Observable<any> {
    return this.http.post(this.api_path.Teacher_School_SelForDDL, {})
  }
  schoolGender_DDL(): Observable<any> {
    return this.http.post(this.api_path.GenderSelForDDL, {})
  }
  GetDistrictList_DDL(): Observable<any> {
    return this.http.post(this.api_path.DistrictDDL, {})
  }
  GetSchoolForTeahCredentials(): Observable<any> {
    return this.http.post(this.api_path.GetSchoolForTeacherAlert, {})
  }
  SendEmailConfig_DDL(): Observable<any> {
    return this.http.post(this.api_path.TeacherEmailConfigSelForDDL, {})
  }
  GetStateListById_DDL(request: any): Observable<any> {
    return this.http.post(this.api_path.stateDDL, request)
  }
  GetBlockDDl(): Observable<any> {
    return this.http.post(this.api_path.BlockDDL, {})
  }
  Block_DDLById(request: any): Observable<any> {
    return this.http.post(this.api_path.Block_DDLById, request)
  }

  //Class DDl
  Class_DDL(): Observable<any> {
    return this.http.post(this.api_path.Class_DDL, {})
  }
  Subject_DDL(): Observable<any> {
    return this.http.post(this.api_path.Subject_DDL, {})
  }
  ASS_Class_bySubject_DDL(request: any): Observable<any> {
    return this.http.post(this.api_path.ASS_Class_bySubject_DDL, request)
  }
  Chapter_DDL(): Observable<any> {
    return this.http.post(this.api_path.Chapter_SelForDDL, {})
  }

  // getCountryDDL(): Observable<any> {
  //   return this.http.post(this.api_path.CountryDDL, {})
  // }
  ASS_TeacherMap_SelForDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.ASS_TeacherMap_SelForDDL, request)
  }

  ASS_Teacher_SelByLocIdForDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.ASS_Teacher_SelByIdForDDL, request)
  }

  BlockDDLById(request: any): Observable<any> {
    return this.http.post(this.api_path.Block_byIdDDL, request)
  }
  DistrictDDL(): Observable<any> {
    return this.http.post(this.api_path.DistrictCCDDL, {})
  }
  getLocationList(payload: any): Observable<any> {
    return this.http.post(this.api_path.UM_Location_By_Id_SelForDDL, payload)
  }
  getLocationListForAddRole(req: any): Observable<any> {
    return this.http.post(this.api_path.UM_Location_SelForDDL_ForAddRole, req)
  }
  DistrictDDLById(request: any): Observable<any> {
    return this.http.post(this.api_path.DistrictDDLById, request)
  }
  getCommon_StateDDL(): Observable<any> {
    return this.http.post(this.api_path.getCommon_StateDDL, {})
  }
  UserByIdDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.UserByIdDDL, request)
  }
  UM_User_SelectForDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.UM_User_SelectForDDL, request)
  }
  UserRoleBySelect_IdDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.UserRoleBySelect_IdDDL, request)
  }

  //Academic Year DDL 
  Academic_DDL(): Observable<any> {
    return this.http.post(this.api_path.ASS_AcademicYear_SelfForDDL, {})
  }
  // School DDL
  SchoolCategory_DDL(): Observable<any> {
    return this.http.post(this.api_path.SchooCategory_SelectForDDl, {})
  }
  SchoolType_DDL(): Observable<any> {
    return this.http.post(this.api_path.SchoolType_SelForDDL, {})
  }


  // Chapter Setup ddl
  ASS_Chapter_SelByIdForDDL(req: any): Observable<any> {
    console.log(req)
    return this.http.post(this.api_path.ASS_Chapter_SelByIdForDDL, req)
  }
  Chapter_SelByNonIdForDDL(request: any): Observable<any> {
    return this.http.post(this.api_path.Chapter_SelByNonIdForDDL, request)
  }

  ACM_EmailConfig_SelForDDL(): Observable<any> {
    return this.http.post(this.api_path.ACM_EmailConfig_SelForDDL, {})
  }

  getDetailsForHome(): Observable<any> {
    return this.http.post(this.api_path.GetOrganizationDetails, {})
  }
  ACM_LeftreasonDDL_SelForDDL(): Observable<any> {
    return this.http.post(this.api_path.ACM_LeftreasonDDL_SelForDDL, {})
  }

  uploadVideoFile(request: any): Observable<any> {
    console.log(request);
    console.log(request.size);
    const formData = new FormData();
    formData.append('files', request)
    console.log(formData);
    return this.http.post(this.api_path.Uploadvideo, formData)
  }

  // dashboard ddl 
  GetDashboardDetails(request: any): Observable<any> {
    return this.http.post(this.api_path.GetDashboardDetails, request)
  }

  Section_SelForDDL_OnLocClassId(request: any): Observable<any> {
    return this.http.post(this.api_path.Section_SelForDDL_OnLocClassId, request)
  }
  Class_SelForDDL_OnLocationId(request: any): Observable<any> {
    return this.http.post(this.api_path.Class_SelForDDL_OnLocationId, request)
  }
  difficulty_DDL(): Observable<any> {
    return this.http.post(this.api_path.ASS_DifficultyLevel_SelForDDL, {})
  }
  topic_DDL(request: any): Observable<any> {
    return this.http.post(this.api_path.ASS_topic_SelByIdForDDL, request)
  }

  GetNotification(req: any): Observable<any> {
    return this.http.post(this.api_path.ACM_ViewNotification_Search, req)
  }

  ASS_Subject_SelForIdDDl(req: any): Observable<any> {
    return this.http.post(this.api_path.ASS_Subject_SelForIdDDl, req)
  }
  ASS_Chapter_SelForIdDDl(req: any): Observable<any> {
    return this.http.post(this.api_path.ASS_Chapter_SelForIdDDl, req)
  }

  getAssessmentChartDetails(req: any): Observable<any> {
    return this.http.post(this.api_path.ASS_StudentAssessmentDetails_Get, req)
  }

  getAssignmentChartDetails(req: any): Observable<any> {
    return this.http.post(this.api_path.ASS_StudentAssignmentDetails_Get, req)
  }

  Profile_User_Get(): Observable<any> {
    return this.http.post(this.api_path.Profile_User_Get, {})
  }
  Profile_User_Upd(request: any): Observable<any> {
    return this.http.post(this.api_path.Profile_User_Upd, request)
  }

}

import { Component } from '@angular/core';
import { interval, Observable, take } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { AssessmentService } from 'src/app/services/assessment.service';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  filenames: string[] = [];
  progressbarVal: any = 50;
  showProgressBar: boolean = true;

  constructor(
    private _common: CommonService,
    private _toast: ToasterService,
    private _assessmentService: AssessmentService,
  ) {

  }

  progress = 0;
  uploadedFile = [];

  ngOnInit() {
  }

  ProgressBar() {

  }

  Showprogress() {
    this.progress = 0;
    setInterval(() => this.ProgressBar(), 2000)
  }

  // timer() {
  //   var timeleft = 6;
  //   var downloadTimer = setInterval(function () {
  //     if (timeleft <= 0) {
  //       clearInterval(downloadTimer); this.progress = 0;

  //     } else {
  //       // document.getElementById("countdown").innerHTML = timeleft + "";
  //     }
  //     timeleft -= 1;
  //   }, 1000);
  //   setInterval(() => clearInterval(downloadTimer), 6000)
  // }


  handleUpload(event: any) {
    this.progress = 0;
    let req = event.target.files;
    for (let file of req) {
      this.progress = this.progress + 10;
      this._common.uploadVideoFile(file).subscribe({
        next: (res) => {
          console.log(res);
          this.uploadedFile.push(res['lstdata'][0]['fileName']);
          console.log()
          if (this.progress >= 100) {
            this.progress = 100;
          }
          console.log(this.uploadedFile);
        },
        error: (error) => {
          let showErrorMsg = this._assessmentService.getErrorMessage(error);
          this._toast.openSnackBarError(showErrorMsg, 'Error');
        }
      })
    }
  }


}


import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MyhttpinterceptorInterceptor } from './interceptor/myhttpinterceptor.interceptor';
import { ToastModule } from 'primeng/toast';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { MatButtonModule } from '@angular/material/button';
import { QCommonModule } from './modules/q-common/q-common.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUploadComponent } from './modules/file-upload/file-upload.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ConfigAService } from './services/config.service';
import { PreloadFactory } from './interface/preload';

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    FileUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FeatherModule.pick(allIcons),
    MatSnackBarModule,
    NgxSpinnerModule,
    ToastModule,
    BrowserAnimationsModule,
    MatButtonModule,
    QCommonModule
  ],
  providers: [
    NgxSpinnerService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyhttpinterceptorInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB', multi:true},
    { provide: 'MAX_FILE_SIZE', useValue: 1000000000},
    {
      provide:LocationStrategy,useClass: HashLocationStrategy
    },

    ConfigAService,
    {
      provide: APP_INITIALIZER,
      deps: [
        ConfigAService
      ],
      multi: true,
      useFactory: PreloadFactory   // use factory to inject service at initialize
    }

  ],
  bootstrap: [AppComponent],
  // schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export const environment: any = {
    production: true,
    // Prod
    // basePath: 'https://app.shikshadaily.com',
    // basePort: '/Shiksha_ADM_API/',
    // baseReportPath: 'https://app.shikshadaily.com',
    // baseReportPort: '/Shiksha_SSRS_API/',
    // urlForTeacher: 'https://app.shikshadaily.com/Teacher/',
    // urlForStudent: 'https://app.shikshadaily.com/Student/',

    //  For GNN
    basePath: 'https://gnn.shikshadaily.com',
    basePort: '/Shiksha_ADM_API/',
    baseReportPath: 'https://app.shikshadaily.com',
    baseReportPort: '/Shiksha_SSRS_API/',
    urlForTeacher: 'https://gnn.shikshadaily.com/Teacher/',
    urlForStudent: 'https://gnn.shikshadaily.com/Student/',
};

import { Injectable } from "@angular/core";
import { FormArray } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UtilityFunctions {

  subMenuList = [];
  currentUrl: any;
  currentMenuList: any;
  authorizationData: any;
  private subject = new Subject<any>();

  constructor(private router: Router, private sanitizer: DomSanitizer) { }
  errorMsg: string = 'Please fill required details.';
  errorMsgFromServer:string = 'Internal server error';
  successSaveMsg: string = 'Data has been successfully saved';
  successUpdateMsg: string = 'Data has been successfully updated';
  succesMailMsg: string = 'Mail has been sent successfully';
  successmsMsg: string = 'SMS has been sent successfully';
  showSuccessMsg: string = 'Data has been deleted successfully';
  fieldRequiredMsg: string = 'This field is required!';
  IsTextValidMsg: any = 'This field accept characters, numbers -,.&()/';
  invalidCharacters ='This field accept characters, numbers, -'
  IsNameValidMsg: any = 'This field accept characters, numbers and .';
  IsRequiredMsg: any = 'This field is required';
  IsMobileNoValidMsg: any = 'Mobile number should be 10 digits and start from 6 to 9';
  IsEmailValidMsg: string = 'Please enter a valid email address';
  IsStringMsg: any = 'This field accept characters';
  IsTextFieldValidMsg: any = 'This field accept characters, numbers and special characters';
  minLengthMSG: any = 'This field accepts minimum 5 digit';
  locationMsg: any = 'Please select library location';
  locationLibraryMsg: any = 'Please select library';
  inventoryMsg: any = 'Please select inventory location';
  storeMsg: any = 'Please select store';
  integerDecimalMsg: any = 'This field accept numbers and decimal'
  whitespaceMsg: any = 'White space not allowed';
  invalidacademicerrmg: any = 'This field accept numbers, - and accepts minimum 9 digit';
  invalidacademic: any = 'Invalid Academic Year.';
  showFlushMsg: string = 'Flush all successfully';
  charOnly : string = 'This field accept Character Only';
  // errorMsgFromServer:string = 'Internal server error';
  noDataFoundMsg:string = 'No records found !';
  characterMsgValidation = 'This field allow only character'

  colorList = ['#8032ae', '#d71868', '#00adad', '#6b8e23', '#5072a7', '#8a496b', '#347C2C', '#FBE7A1', '#FFFF33', '#C8AD7F', '#FF6700', '#C48189', '#EE82EE', '#7575CF', '#800080', '#0048BA', '#B0BF1A', '#7CB9E8', '#B284BE', '#C46210', '#E52B50', '#9F2B68', '#FFBF00', '#3B7A57', '#8DB600', '#FBCEB1', '#27346F', '#FF9966', '#FFEF00', '#00FF00', '#1B4D3E', '#15F4EE', '#86608E', '#6C541E', '#96C8A2', '#6C3082', '#50C878', '#00CED1', '#665D1E', '#3D3635', '#D462FF',]


  countryList: any = [
    { Text: 'India', ID: 103 }
  ]

  createAndDownloadBlobFile(body: any, options: any, filename: any) {
    var blob = new Blob([body]);
    // if (window.navigator.msSaveBlob) {
    //   navigator.msSaveBlob(blob, filename);
    // }
    // else {
    var link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    // }
  }

  ifValidDataExists(data: any) {
    if (data !== null && data !== undefined && data !== '') {
      return true;
    } else {
      return false;
    }
  }

  getRandomString(length: any) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  getEncryptedValue(value: any) {
    let key = "0123456789123456";
    var iv = this.getRandomString(16);
    let ciphertext = this.encrypt(iv, key, value);
    let ciphertextConversion = (ciphertext + ":" + iv);
    let encyrptedValue = btoa(ciphertextConversion);
    return encyrptedValue;
  }

  getEncryptedValueWithHash(plaintext: any, salt: any) {
    var hash: any = CryptoJS.SHA256(plaintext);
    hash = hash.toString(CryptoJS.enc.Hex);
    hash = hash + salt;
    hash = CryptoJS.SHA256(hash);
    hash = hash.toString(CryptoJS.enc.Hex);
    return hash;
  }

  getEncryptedValueWithHashWithoutSalt(plaintext: any) {
    var hash: any = CryptoJS.SHA256(plaintext);
    hash = hash.toString(CryptoJS.enc.Hex);
    return hash;
  }

  getDecryptedValue(val: any) {
    let key = "0123456789123456";
    let encryptedVal = atob(val);
    let decyrptedValue = this.decrypt(encryptedVal.split(':')[1], encryptedVal.split(':')[0], key);
    return decyrptedValue;
  }

  getDecryptedValueAadhar(val: any) {
    let key = "ALURGHIKPOLMQMNI";
    let encryptedVal = atob(val);
    let decyrptedValue = this.decrypt(encryptedVal.split(':')[1], encryptedVal.split(':')[0], key);
    return decyrptedValue;
  }

  // Encrypted Value For LocationId
  getEncryptedValueForLocationId(value: any) {
    let key = "XYTRGHIKPOLMQWED";
    var iv = this.getRandomString(16);
    let ciphertext = this.encrypt(iv, key, value);
    let ciphertextConversion = (ciphertext + ":" + iv);
    let encyrptedValue = btoa(ciphertextConversion);
    return encyrptedValue;
  }

  encrypt(iv: any, passPhrase: any, plainText: any) {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plainText),
      CryptoJS.enc.Utf8.parse(passPhrase),
      { iv: CryptoJS.enc.Utf8.parse(iv), keySize: 128 / 8, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted;
  }

  decrypt(iv: any, encText: any, key: any) {
    var decrypt = CryptoJS.AES.decrypt(
      encText,
      CryptoJS.enc.Utf8.parse(key),
      { iv: CryptoJS.enc.Utf8.parse(iv), keySize: 128 / 8, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8);
    return decrypt;
  }
  sendMessageSibblingComponents(message: string) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // getSantizeUrl(url: string) {
  //   if (url) {
  //     return this.sanitizer.bypassSecurityTrustUrl(url);
  //   }
  // }

  getMenuAuthorizationData() {
    let submenu: any = sessionStorage.getItem('subMenu');
    let subMenu1: any = JSON.parse(submenu)
    this.subMenuList = subMenu1
    console.log(this.subMenuList);
    this.currentUrl = this.router.url;
    console.log(this.currentUrl);
    let result: any = this.subMenuList?.find((item: any) => this.currentUrl.includes(item.pagepath));
    console.log(result);
    return result;
  }

  // getMenuAuthorizationDataForGrid() {
  //   this.subMenuList = JSON.parse(sessionStorage.getItem('subMenu'));
  //   this.currentUrl = this.router.url;
  //   let result = this.subMenuList?.find(item => item.pagepath == this.currentUrl);
  //   return result;
  // }

  // getSubMenuAuthorizationData() {
  //   this.subMenuList = JSON.parse(sessionStorage.getItem('subMenu'));
  //   this.currentUrl = this.router.url;
  //   this.currentMenuList = this.subMenuList.find(item => this.currentUrl.includes(item.pagepath));
  //   let result = this.currentMenuList?.SubMenuList.find(item => item.pagepath == this.currentUrl);
  //   return result;
  // }
  // getsubMenuSubAuthorizationData() {
  //   this.subMenuList = JSON.parse(sessionStorage.getItem('subMenu'));
  //   this.currentUrl = this.router.url;
  //   let result;
  //   this.subMenuList.forEach(element => {
  //     element.SubMenuList.forEach(ele => {
  //       if (ele.pagepath == this.currentUrl) {
  //         result = ele;
  //       }
  //     });
  //   });
  //   return result;
  // }
  // for sub menu add page 
  // getSubMenuAuthorizationDataForAdd() {
  //   this.subMenuList = JSON.parse(sessionStorage.getItem('subMenu'));
  //   this.currentUrl = this.router.url;
  //   this.currentMenuList = this.subMenuList.find(item => this.currentUrl.includes(item.pagepath));
  //   let result = this.currentMenuList?.SubMenuList.find(item => this.currentUrl.includes(item.pagepath));
  //   return result;
  // }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  getSantizeUrl(url: string) {
    // if (url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
    // }
  }

  searchParentList(query: string, LocList:any) {
    let result = this.select(query?.toLowerCase(), LocList)
    return result;
  }

  select(query: string, LocList:any): string[] {
    let result: string[] = [];
    for (let a of LocList) {
      if (a.locationname?.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }


  searchChapter(query: string, LocList:any) {
    let result = this.selectChapter(query.toLowerCase(), LocList)
    return result;
  }

  selectChapter(query: string, LocList:any): string[] {
    let result: string[] = [];
    for (let a of LocList) {
      if (a.chaptername.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }

  getErrorMessage(error:any) {
    console.log(error);
    if(error['status'] == 400 && error.error['message']) {
      return error.error['message']
    } else if (error['status'] == 500 ) {
      return 'Internal server error, Please contact to administrator'
    } else {
      return 'Some error has been occurred, Please contact to administrator'
    }
  }


}

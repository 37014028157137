import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToasterService } from '../services/toaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';

@Injectable(
  { providedIn: 'root' }
)
export class MyhttpinterceptorInterceptor implements HttpInterceptor {
  count = 0;
  constructor(private spinner: NgxSpinnerService, private router: Router, private toaster: ToasterService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.router.url === "/auth/school/schoolsetup/topic-video-upload") {
      this.spinner.hide();
    
      this.count++;
      return next.handle(request)
        .pipe(tap(
          event => {
          },
          error => {
            if (error.status == 401) {
              this.toaster.openSnackBarError(error.error.Message, 'Error');
              sessionStorage.clear();
              this.router.navigate(["/account"]);
            }
          }

        ), finalize(() => {
          this.count--;
          if (this.count == 0) this.spinner.hide()
        })
        );

    } else {
      this.spinner.show()

      this.count++;
      return next.handle(request)
        .pipe(tap(
          event => {
          },
          error => {
            if (error.status == 401) {
              this.toaster.openSnackBarError(error.error.Message, 'Error');
              sessionStorage.clear();
              this.router.navigate(["/account"]);
            }
          }

        ), finalize(() => {
          this.count--;
          if (this.count == 0) this.spinner.hide()
        })
        );
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaginatorModule} from 'primeng/paginator';
import { QCommonRoutingModule } from './q-common-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { PanelMenuModule } from 'primeng/panelmenu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import { allIcons } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PickListModule } from 'primeng/picklist';
import { TreeTableModule } from 'primeng/treetable';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CalendarModule } from 'primeng/calendar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChartModule } from 'primeng/chart';

// import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QCommonRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    CommonModule, 
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatRadioModule,
    MatStepperModule, 
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule, 
    RouterModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatTreeModule,
    MatDialogModule, 
    MatTooltipModule, 
    MatProgressSpinnerModule,
    MatTabsModule,
    PanelMenuModule,
    MatSnackBarModule,
    TableModule,
    ButtonModule,
    BreadcrumbModule,
    FeatherModule.pick(allIcons),
    MatSlideToggleModule,
    PaginatorModule,
    SidebarModule,
    MenubarModule,
    MatPaginatorModule,
    ConfirmDialogModule,
    NgxSpinnerModule,
    TabViewModule,
    DialogModule,
    AngularEditorModule,
    PickListModule,
    TreeTableModule,
    ProgressBarModule,
    FileUploadModule,
    RadioButtonModule,
    MatProgressBarModule,
    CalendarModule,
    DragDropModule,
    OverlayPanelModule,
    ChartModule
    // PerfectScrollbarModule
  ],
  exports:[
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    CommonModule,
    QCommonRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    CommonModule, 
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatRadioModule,
    MatStepperModule, 
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule, 
    RouterModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatTreeModule,
    MatDialogModule, 
    MatTooltipModule, 
    MatProgressSpinnerModule,
    MatTabsModule,
    PanelMenuModule,
    MatSnackBarModule,
    TableModule,
    ButtonModule,
    BreadcrumbModule,
    FeatherModule,
    MatSlideToggleModule,
    PaginatorModule,
    SidebarModule,
    MenubarModule,
    MatPaginatorModule,
    ConfirmDialogModule,
    NgxSpinnerModule,
    TabViewModule,
    DialogModule,
    AngularEditorModule,
    PickListModule,
    TreeTableModule,
    ProgressBarModule,
    FileUploadModule,
    RadioButtonModule,
    MatProgressBarModule,
    CalendarModule,
    DragDropModule,
    OverlayPanelModule,
    ChartModule
    // PerfectScrollbarModule
  ],
  providers:[MatDatepickerModule,MatNativeDateModule, DatePipe,
  // {
  //   provide:PERFECT_SCROLLBAR_CONFIG,useValue:PERFECT_SCROLLBAR_CONFIG
  // }
  ]
})
export class QCommonModule { }

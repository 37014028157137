import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  userData: any;
  constructor(
    public router: Router,
    public ngZone: NgZone 
  ) { }


  isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('userData')!);
    if (user?.isfirstlogin) {
      // router.navigate(['/account']);
      return false;
    } else if (user && user.authToken && user.active && !user.errorMessage) {
      return true;
    } else {
      // router.navigate(['/account']);
      return false;
    }  
  }

}

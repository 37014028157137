import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { API_PATH } from '../utility/api-constant';
// import { environment } from "src/environments/environment.development"


@Injectable({
  providedIn: 'root'
})
export class ConfigAService {
  API_PATH: any;
  environment = {
    basePath: '',
    basePort: '',
    baseReportPath: '',
    baseReportPort: '',
    urlForTeacher: '',
    urlForStudent: ''
  };
  constructor(
    private http: HttpClient,
  ) {
    console.log(1);
  }


  getBaseURL() {
    this.http.get('assets/api_config.json').subscribe(res => {
      this.environment.basePath = res['basePath'];
      this.environment.basePort = res['basePort'];
      this.environment.baseReportPath = res['baseReportPath'];
      this.environment.baseReportPort = res['baseReportPort'];
      this.environment.urlForTeacher = res['urlForTeacher'];
      this.environment.urlForStudent = res['urlForStudent'];
    })
    setTimeout((ele: any) => {
      this.setEnvirment(this.environment);
    }, 200)
  }

  setEnvirment(environment) {
    this.API_PATH = {
      'GENERATE_SALT': environment.basePath + environment.basePort + 'api/Home/GenerateSalt',
      'LOGIN_API': environment.basePath + environment.basePort + 'api/Home/Index',
      'Logout': environment.basePath + environment.basePort + 'api/Home/Logout',
      'Forgotpassword': environment.basePath + environment.basePort + 'api/Home/Forgotpassword',
      'Changepassword': environment.basePath + environment.basePort + 'api/Home/ChangePassword',

      //District Setup
      'DistrictGet': environment.basePath + environment.basePort + 'api/ACM/DistrictGet',
      'DistrictSearch': environment.basePath + environment.basePort + 'api/ACM/DistrictSearch',
      'AddDistrict': environment.basePath + environment.basePort + 'api/ACM/AddDistrict',
      'District_Upd': environment.basePath + environment.basePort + 'api/ACM/District_Upd',
      'DistrictDel': environment.basePath + environment.basePort + 'api/ACM/DistrictDel',
      'GetMenuAccess': environment.basePath + environment.basePort + 'api/Home/GetMenuAccess',

      //SetUp Page
      'Page_Search': environment.basePath + environment.basePort + 'api/UM/UM_Page_Search',
      'Module_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Module_SelForDDL',
      'PageType_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_PageType_SelForDDL',
      'AddPageMst': environment.basePath + environment.basePort + 'api/UM/AddPageMst',
      'UpdatePageMst': environment.basePath + environment.basePort + 'api/UM/UpdatePageMst',
      'Page_Get': environment.basePath + environment.basePort + 'api/UM/UM_Page_Get',
      'Page_Del': environment.basePath + environment.basePort + 'api/UM/UM_Page_Del',
      'UM_ParentPage_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_ParentPage_SelForDDL',
      'search_Acadmicyear': environment.basePath + environment.basePort + 'api/UM/ActionTypeList',


      //ddl
      'stateDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_State_SelForDDL',
      'CountryDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Country_SelForDDL',
      'BlockDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_SelForDDL',
      'DistrictDDLById': environment.basePath + environment.basePort + 'api/ACM/ACM_District_byId_SelectForDDl',
      'getCommon_StateDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_State_Current_SelForDDl',
      'Block_DDLById': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_byId_SelForDDL',
      'UserByIdDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_By_loc_SelForDDL',
      'UserRoleBySelect_IdDDL': environment.basePath + environment.basePort + 'api/UM/UM_Role_ById_SelectForDDL',
      'UM_User_SelectForDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_By_Loca_SelectForDDL',

      //Organization setup
      'AddOrganization': environment.basePath + environment.basePort + 'api/ACM/AddOrganization',
      'GetOrganization': environment.basePath + environment.basePort + 'api/ACM/GetOrganization',
      'DistrictDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_District_SelForDDL',
      'getCountryDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Country_SelForDDL',

      //Module Setup Page
      'ModuleSearch': environment.basePath + environment.basePort + 'api/UM/Module_Search',
      // 'Module_SelForDDL': environment.basePath + environment.basePort +'api/UM/UM_Module_SelForDDL',
      // 'PageType_SelForDDL': environment.basePath + environment.basePort +'api/UM/UM_PageType_SelForDDL',
      'AddModule': environment.basePath + environment.basePort + 'api/UM/AddModule',
      'Module_Upd': environment.basePath + environment.basePort + 'api/UM/UpdateModule',
      'ModuleGet': environment.basePath + environment.basePort + 'api/UM/GetModule',
      'ModuleDel': environment.basePath + environment.basePort + 'api/UM/DeleteModule',

      //Academic Year Page
      'AcademicYearSearch': environment.basePath + environment.basePort + 'api/ACM/AcademicYearSearch',
      'AddAcademicYear': environment.basePath + environment.basePort + 'api/ACM/AddAcademicYear',
      'AcademicYear_Upd': environment.basePath + environment.basePort + 'api/ACM/AcademicYear_Upd',
      'AcademicYearGet': environment.basePath + environment.basePort + 'api/ACM/AcademicYearGet',
      'AcademicYearDelete': environment.basePath + environment.basePort + 'api/ACM/AcademicYearDelete',
      'AcademicYearDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_AcademicYear_SelForDDL',
      'Month_Display_Search': environment.basePath + environment.basePort + 'api/ACM/MonthSearch',
      'Year_Display_Search': environment.basePath + environment.basePort + 'api/ACM/YearSearch',
      'Gender_Display_Search': environment.basePath + environment.basePort + 'api/ACM/GenderSearch',
      'PageType_Display_Search': environment.basePath + environment.basePort + 'api/UM/PageTypeSearch',

      // STATE PAGE DDL 
      'StateSearch': environment.basePath + environment.basePort + 'api/ACM/ACMStateSearch',
      'StateDelete': environment.basePath + environment.basePort + 'api/ACM/ACMStateDel',
      'StateGet': environment.basePath + environment.basePort + 'api/ACM/ACMStateGet',
      'StateADD': environment.basePath + environment.basePort + 'api/ACM/AddACMState',
      'StateUpdate': environment.basePath + environment.basePort + 'api/ACM/ACMState_Upd',

      // User Registration 
      'UserRegSearch': environment.basePath + environment.basePort + 'api/UM/UM_User_Search',
      'UserRegAdd': environment.basePath + environment.basePort + 'api/UM/AddUser',
      'UserRegUpd': environment.basePath + environment.basePort + 'api/UM/UpdateUser',
      'UserRegGet': environment.basePath + environment.basePort + 'api/UM/UM_User_Get',
      'UserRegDel': environment.basePath + environment.basePort + 'api/UM/UM_User_Del',
      'LocationDDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL',
      'UserRoleDDL': environment.basePath + environment.basePort + 'api/UM/UM_Role_SelForDDL',
      'UsersendMail': environment.basePath + environment.basePort + 'api/UM/SendCredMail',



      //Block Setup
      'BlockSearch': environment.basePath + environment.basePort + 'api/ACM/BlockSearch',
      'BlockDel': environment.basePath + environment.basePort + 'api/ACM/BlockDel',
      'AddBlock': environment.basePath + environment.basePort + 'api/ACM/AddBlock',
      'Block_Upd': environment.basePath + environment.basePort + 'api/ACM/Block_Upd',
      'BlockGet': environment.basePath + environment.basePort + 'api/ACM/BlockGet',

      // country search
      'countrysearch': environment.basePath + environment.basePort + 'api/ACM/CountrySearch',


      //Class Setup
      'Class_INSERT': environment.basePath + environment.basePort + 'api/ASS/ASSClassAdd',
      'Class_Search': environment.basePath + environment.basePort + 'api/ASS/ASSClassSearch',
      'Class_Get': environment.basePath + environment.basePort + 'api/ASS/ASSClassGet',
      'Class_Update': environment.basePath + environment.basePort + 'api/ASS/ASSClassUpdate',
      'Class_Del': environment.basePath + environment.basePort + 'api/ASS/ASSClassDel',

      //Section Setup
      'Section_INSERT': environment.basePath + environment.basePort + 'api/ASS/ASSSectionAdd',
      'Section_Search': environment.basePath + environment.basePort + 'api/ASS/ASSSectionSearch',
      'Section_Get': environment.basePath + environment.basePort + 'api/ASS/ASSSectionGet',
      'Section_Update': environment.basePath + environment.basePort + 'api/ASS/ASSSectionUpdate',
      'Section_Delete': environment.basePath + environment.basePort + 'api/ASS/ASSSectionDel',


      //School Type
      'SchoolTypeSearch': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeSearch',
      'SchoolTypeAdd': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeAdd',
      'SchoolType_Upd': environment.basePath + environment.basePort + 'api/ASS/ASSSchooltypeUpdate',
      'SchoolTypeGet': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeGet',
      'SchoolTypeDel': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeDel',

      //Page Actiom Map
      'PageAction_Map_Search': environment.basePath + environment.basePort + 'api/UM/UM_PageAction_Map_Search',
      'UM_Page_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Page_SelForDDL',

      //Group Api
      'GroupSearch': environment.basePath + environment.basePort + 'api/ASS/ASSGroupSearch',
      'GroupAdd': environment.basePath + environment.basePort + 'api/ASS/ASSGroupAdd',
      'GroupUpdate': environment.basePath + environment.basePort + 'api/ASS/ASSGroupUpdate',
      'GroupGet': environment.basePath + environment.basePort + 'api/ASS/ASSGroupGet',
      'GroupDel': environment.basePath + environment.basePort + 'api/ASS/ASSGroupDel',
      'UM_User_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_SelForDDL',

      //addional Permission 
      'UserRole_Rights_Search': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Search',
      'AddUserRoleRights': environment.basePath + environment.basePort + 'api/UM/AddUserRoleRights',
      'UpdateUserRole_Rights': environment.basePath + environment.basePort + 'api/UM/UpdateUserRoleRights',
      'UserRole_Rights_Get': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Get',
      'UserRole_Rights_Del': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Del',

      //assign-permission-to-role
      'Assignpertorolesearch': environment.basePath + environment.basePort + 'api/UM/UM_RolePage_Rights_Search',
      'assignpertoroleadd': environment.basePath + environment.basePort + 'api/UM/AddRolePageRights',
      //Email Configuration
      'EmailConfig_Mst_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Search',
      'EmailConfig_Mst_Del': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Del',
      'EmailConfig_Mst_Ins': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Ins',
      'EmailConfig_Mst_Upd': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Upd',
      'EmailConfig_Mst_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Get',

      //Teacher Setup
      'Ass_Teacher_Search': environment.basePath + environment.basePort + 'api/School/Ass_Teacher_Search',
      'Teacher_School_SelForDDL': environment.basePath + environment.basePort + 'api/School/GetSchoolForTeacherAlert',
      'ASS_Teacher_Ins': environment.basePath + environment.basePort + 'api/School/ASS_Teacher_Ins',
      'ASS_Teacher_Upd': environment.basePath + environment.basePort + 'api/School/ASS_Teacher_Upd',
      'ASS_Teacher_Get': environment.basePath + environment.basePort + 'api/School/ASS_Teacher_Get',
      'ASS_Teacher_Del': environment.basePath + environment.basePort + 'api/School/ASS_Teacher_Del',
      'GenderSelForDDL': environment.basePath + environment.basePort + 'api/ACM/GenderSelForDDL',

      //password reset In UM
      'passwordResetSearch': environment.basePath + environment.basePort + 'api/UM/ResetPasswordUserSearch',
      'passwordResetMail': environment.basePath + environment.basePort + 'api/UM/ResetPasswordSendCredMail',
      'UserblockUnblock': environment.basePath + environment.basePort + 'api/UM/UserBlockandUnblock',

      //Language Page
      'Language_Search': environment.basePath + environment.basePort + 'api/ACM/LanguageSearch',
      'Add_Language': environment.basePath + environment.basePort + 'api/ACM/AddLanguage',
      // 'Language_Update': environment.basePath + environment.basePort + 'api/ACM/AcademicYear_Upd',
      'Language_Get': environment.basePath + environment.basePort + 'api/ACM/LanguageGet',
      'Language_Delete': environment.basePath + environment.basePort + 'api/ACM/LanguageDel',

      //Teacher Import
      'UploadExcelDocument': environment.basePath + environment.basePort + 'api/School/UploadExcelDocument',
      'InsertExcelDocument': environment.basePath + environment.basePort + 'api/School/ADM_ImportExcel_Ins',


      //Location School
      'Location_Search': environment.basePath + environment.basePort + 'api/UM/UM_Location_Search',
      'Location_Del': environment.basePath + environment.basePort + 'api/UM/UM_Location_Del',
      'SchoolType_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_SchoolType_SelectForDDl',
      'SchooCategory_SelectForDDl': environment.basePath + environment.basePort + 'api/UM/UM_SchooCategory_SelectForDDl',
      'AddLocationMst': environment.basePath + environment.basePort + 'api/UM/AddLocationMst',
      'UpdateLocationMst': environment.basePath + environment.basePort + 'api/UM/UpdateLocationMst',
      'Location_Get': environment.basePath + environment.basePort + 'api/UM/UM_Location_Get',

      //Role Setup
      'Role_Search': environment.basePath + environment.basePort + 'api/UM/UM_Role_Search',
      'Role_Del': environment.basePath + environment.basePort + 'api/UM/UM_Role_Del',
      'AddRoleMst': environment.basePath + environment.basePort + 'api/UM/AddRoleMst',
      'UpdateRoleMst': environment.basePath + environment.basePort + 'api/UM/UpdateRoleMst',
      'Role_Get': environment.basePath + environment.basePort + 'api/UM/UM_Role_Get',

      //send credentinals to teacher
      'GetSchoolForTeacherAlert': environment.basePath + environment.basePort + 'api/School/GetSchoolForTeacherAlert',
      'TeacherSearchForCredentials': environment.basePath + environment.basePort + 'api/School/Ass_Teacher_Search',
      'TeacherEmailConfigSelForDDL': environment.basePath + environment.basePort + 'api/School/TeacherEmailConfigSelForDDLForAlert',
      'sendMailtoteacher': environment.basePath + environment.basePort + 'api/School/SendTeacherCredMail',

      //Student setup Api 
      'ASS_Student_Search': environment.basePath + environment.basePort + 'api/School/ASS_Student_Search',
      'ASS_Student_Get': environment.basePath + environment.basePort + 'api/School/ASS_Student_Get',
      'ASS_Student_Ins': environment.basePath + environment.basePort + 'api/School/ASS_Student_Ins',
      'ASS_Student_Upd': environment.basePath + environment.basePort + 'api/School/ASS_Student_Upd',
      'ASS_Student_Del': environment.basePath + environment.basePort + 'api/School/ASS_Student_Del',
      'Class_DDL': environment.basePath + environment.basePort + 'api/ASS/ASS_Class_SelForDDL',
      'Section_DDL': environment.basePath + environment.basePort + 'api/ASS/ASS_Section_SelForDDL',

      //Redis Configuration
      'RedisConfiguration': environment.basePath + environment.basePort + 'api/ACM/RedisConfiguration',
      'RedisKeys': environment.basePath + environment.basePort + 'api/ACM/RedisKeys',
      'FlushAll': environment.basePath + environment.basePort + 'api/ACM/FlushAll',
      'FlushAllWithoutLogout': environment.basePath + environment.basePort + 'api/ACM/FlushAllWithoutLogout',
      'RemoveKey': environment.basePath + environment.basePort + 'api/ACM/RemoveKey',
      'GetKeyValue': environment.basePath + environment.basePort + 'api/ACM/GetKeyValue',


      //Signature ACM Api 
      'SignatureConfig_Mst_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Get',
      'ACM_SignatureConfig_Mst_Ins': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Ins',
      'ACM_SignatureConfig_Mst_Upd': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Upd',
      'SignatureConfig_Mst_Delete': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Del',
      'SignatureConfig_Mst_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Search',

      //Student Import
      'UploadExcelStudentDocument': environment.basePath + environment.basePort + 'api/School/StudentUploadExcelDocument',
      'InsertExcelStudentDocument': environment.basePath + environment.basePort + 'api/School/StudentExcelInsert',

      //School Category
      'SchoolCategory_Search': environment.basePath + environment.basePort + 'api/School/SchoolCategory_Search',
      'SchoolCategory_INSERT': environment.basePath + environment.basePort + 'api/School/SchoolCategory_Ins',
      'SchoolCategory_UPDATE': environment.basePath + environment.basePort + 'api/School/SchoolCategoryUpdate',
      'SchoolCategory_DELETE': environment.basePath + environment.basePort + 'api/School/SchoolCategory_Del',
      'SchoolCategory_GET': environment.basePath + environment.basePort + 'api/School/SchoolCategory_Get',

      //chapter setup
      'ChapterSetup_Search': environment.basePath + environment.basePort + 'api/School/ChapterSetup_Search',
      'ChapterSetup_INSERT': environment.basePath + environment.basePort + 'api/School/ChapterSetup_Ins',
      'ChapterSetup_UPDATE': environment.basePath + environment.basePort + 'api/School/ChapterSetupUpdate',
      'ChapterSetup_DELETE': environment.basePath + environment.basePort + 'api/School/ChapterSetup_Del',
      'ChapterSetup_GET': environment.basePath + environment.basePort + 'api/School/ChapterSetup_Get',

      //Subject setup
      'SubjectSetup_Search': environment.basePath + environment.basePort + 'api/School/SubjectSetup_Search',
      'SubjectSetup_INSERT': environment.basePath + environment.basePort + 'api/School/SubjectSetup_Ins',
      'SubjectSetup_UPDATE': environment.basePath + environment.basePort + 'api/School/SubjectSetupUpdate',
      'SubjectSetup_DELETE': environment.basePath + environment.basePort + 'api/School/SubjectSetup_Del',
      'SubjectSetup_GET': environment.basePath + environment.basePort + 'api/School/SubjectSetup_Get',
      // 'SignatureConfig_Mst_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Get',
      // 'SignatureConfig_Mst_InsUpd': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_InsUpd',
      // 'SignatureConfig_Mst_Delete': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Del',
      // 'SignatureConfig_Mst_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_SignatureConfig_Mst_Search',

      //Student Import
      // 'UploadExcelStudentDocument': environment.basePath + environment.basePort + 'api/School/StudentUploadExcelDocument',
      // 'InsertExcelStudentDocument': environment.basePath + environment.basePort + 'api/School/StudentExcelInsert',

      //School Category
      // 'SchoolCategory_Search': environment.basePath + environment.basePort + 'api/School/SchoolCategory_Search',
      // 'SchoolCategory_INSERT': environment.basePath + environment.basePort + 'api/School/SchoolCategory_InsUpd',
      // 'SchoolCategory_UPDATE': environment.basePath + environment.basePort + 'api/School/SchoolCategoryUpdate',
      'Chapter_SelByNonIdForDDL': environment.basePath + environment.basePort + 'api/School/ASS_Chapter_SelByNonIdForDDL',
      'ASS_Chapter_SelByIdForDDL': environment.basePath + environment.basePort + 'api/School/ASS_Chapter_SelByIdForDDL',
      'ACM_EmailConfig_SelForDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_SelForDDL',
      'ACM_LeftreasonDDL_SelForDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_LeftreasonDDL',
      'Section_SelForDDL_OnLocClassId': environment.basePath + environment.basePort + 'api/School/ASS_Section_SelForDDL_OnLocClassId',
      'Class_SelForDDL_OnLocationId': environment.basePath + environment.basePort + 'api/School/ASS_Class_SelForDDL_OnLocationId',

      //Email & SMS Template  
      'EmailTemplate_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailTemplate_Search',
      'EmailTemplate_Del': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailTemplate_Del',
      'EmailTemplate_Add': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailTemplate_Add',
      'EmailTemplate_Udp': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailTemplate_Udp',
      'EmailTemplate_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailTemplate_Get',

      //Question Type
      'QuestionTypeSearch': environment.basePath + environment.basePort + 'api/School/QuestionTypeSearch',
      'ASS_Class_bySubject_DDL': environment.basePath + environment.basePort + 'api/School/ASS_Class_bySubject_DDL',

      'EmailTemplateType_ForDDl': environment.basePath + environment.basePort + 'api/UM/UM_EmailTemplateType_ForDDl',
      'NegativeMarksSearch': environment.basePath + environment.basePort + 'api/School/NegativeMarksSearch',

      //Answer Options
      'AnswerOptionsSearch': environment.basePath + environment.basePort + 'api/School/AnswerOptionsSearch',

      //Dificullty Level
      'DifficultyLevelSearch': environment.basePath + environment.basePort + 'api/School/DifficultyLevelSearch',
      'QuestionPickingSearch': environment.basePath + environment.basePort + 'api/School/QuestionPickingSearch',

      'Location_RepotsDwnld': environment.basePath + environment.basePort + 'api/ReportViewer/UM_Location_Rpt',
      'PageType_RepotsDwnld': environment.basePath + environment.basePort + 'api/ReportViewer/UM_PageType_Rpt',
      //Topic Setup
      'TopicSetup_Search': environment.basePath + environment.basePort + 'api/School/TopicSetup_Search',
      'TopicSetup_Ins': environment.basePath + environment.basePort + 'api/School/TopicSetup_Ins',
      'TopicSetupUpdate': environment.basePath + environment.basePort + 'api/School/TopicSetupUpdate',
      'TopicSetup_Get': environment.basePath + environment.basePort + 'api/School/TopicSetup_Get',
      'TopicSetup_Del': environment.basePath + environment.basePort + 'api/School/TopicSetup_Del',

      //DDL
      'Subject_DDL': environment.basePath + environment.basePort + 'api/School/ASS_Subject_SelForDDL',
      'Chapter_SelForDDL': environment.basePath + environment.basePort + 'api/School/ASS_Chapter_SelForDDL',

      'UM_Role_Report': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/Role_PDF_EXCEL',
      'UM_Role_Permission_Report': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/Role_Permission_PDF_EXCEL',

      'UM_PageType_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_PageType_Rpt_PDF_EXCEL',
      'LocationList_Rpt_PDF_EXCELRepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_LocationList_Rpt_PDF_EXCEL',
      'Block_byIdDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_byId_SelForDDL',
      'DistrictCCDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_District_SelForDDL',
      'ACM_LocationList_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_LocationList_Search',
      'UM_Location_By_Id_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_By_Id_SelForDDL',
      'UM_Location_SelForDDL_ForAddRole': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL_ForAddRole',
      'User_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/User_PDF_EXCEL',
      'UM_User_List_Search': environment.basePath + environment.basePort + 'api/UM/UM_User_List_Search',
      'View_auditlog_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_Audit_Log_PDF_EXCEL',
      'UM_Page_SelIdForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Page_SelIdForDDL',
      'User_Permission_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/User_Permission_PDF_EXCEL',
      'Users_GetlocationCascade_DDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_By_Id_SelForDDL',
      'Module_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_Module_PDF_EXCEL',

      'TeacherList_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_TeacherList_Rpt_PDF_EXCEL',
      'ASS_TeacherProfile_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_TeacherProfile_Rpt_PDF_EXCEL',
      'ASS_Class_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_Class_Rpt_PDF_EXCEL',
      'ASS_Group_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_Group_Rpt_PDF_EXCEL',
      'ASS_SchoolCategory_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_SchoolCategory_Rpt_PDF_EXCEL',
      'ASS_SchoolType_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_SchoolType_Rpt_PDF_EXCEL',
      'ASS_Section_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_Section_Rpt_PDF_EXCEL',

      //School-Class AND Subject Mapping
      'School_Class_SubjectSearch': environment.basePath + environment.basePort + 'api/School/School_Class_SubjectSearch',
      'UpdateSchool_Class_SubjectInsUpd': environment.basePath + environment.basePort + 'api/School/School_Class_Subject_Upd',
      'AddSchool_Class_SubjectInsUpd': environment.basePath + environment.basePort + 'api/School/School_Class_Subject_Ins',
      'School_Class_SubjectGet': environment.basePath + environment.basePort + 'api/School/School_Class_SubjectGet',
      'ASS_SchoolClassMaping_Classes_View': environment.basePath + environment.basePort + 'api/School/ASS_SchoolClassMaping_Classes_View',
      'School_Class_SubjectDel': environment.basePath + environment.basePort + 'api/School/School_Class_SubjectDel',
      'ASS_SchoolClassMaping_Subjects_View': environment.basePath + environment.basePort + 'api/School/ASS_SchoolClassMaping_Subjects_View',


      //Class Section Group
      'Class_Section_GroupSearch': environment.basePath + environment.basePort + 'api/School/Class_Section_GroupSearch',
      'Class_Section_Group_View': environment.basePath + environment.basePort + 'api/School/Class_Section_Group_View',
      'Class_Section_Group_List_View': environment.basePath + environment.basePort + 'api/School/Class_Section_Group_List_View',
      'AddClass_Section_GroupInsUpd': environment.basePath + environment.basePort + 'api/School/Class_Section_Group_Ins',
      'StudentList_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_StudentList_Rpt_PDF_EXCEL',
      'SchoolList_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_SchoolList_Rpt_PDF_EXCEL',

      //Assessment Creation
      'Assessment_QuestionSearch_Search': environment.basePath + environment.basePort + 'api/Assessment/Assessment_QuestionSearch_Search',
      'Assessment_Feedback_Question_Mst_Get': environment.basePath + environment.basePort + 'api/Assessment/ASS_Assessment_Question_Mst_Get',
      'Assessment_Feedbackform_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_Assessment_Search',
      'AssessmentCreate': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentForm_Upd',
      'Assessment_Feedbackform_Get': environment.basePath + environment.basePort + 'api/Assessment/ASS_Assessmentform_Get',
      'FAM_Feedbackform_Del': environment.basePath + environment.basePort + 'api/Assessment/ASS_Assessmentform_Del',
      'Assessment_Feedbackform_Questions_Del': environment.basePath + environment.basePort + 'api/Assessment/ASS_Assessmentform_Questions_Del',



      //Question Setup
      'Question_Setup_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_Question_Mst_Search',
      'Question_Setup_INSERT': environment.basePath + environment.basePort + 'api/Assessment/ASS_QuestionSetup_Ins',
      'Question_Setup_UPDATE': environment.basePath + environment.basePort + 'api/Assessment/ASS_QuestionSetup_Upd',
      'Question_Setup_GET': environment.basePath + environment.basePort + 'api/Assessment/ASS_Question_Mst_Get',
      'Question_Setup_DELETE': environment.basePath + environment.basePort + 'api/Assessment/ASS_Question_Mst_Del',
      'ASS_AssessmentRating_SelForDDL': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentRating_SelForDDL',

      //List of Questions
      'ASS_QuestionList_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_QuestionList_Rpt_PDF_EXCEL',

      //Student Subject Map
      'ASS_StudentSubject_Map_Search': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubject_Map_Search',
      'ASS_AcademicYear_SelfForDDL': environment.basePath + environment.basePort + 'api/School/ASS_AcademicYear_SelfForDDL',
      'ASS_StudentSubjectList_Search': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubjectList_Search',
      'ADD_ASS_StudentSubject_Map_InsUpd': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubject_Map_Ins',
      'ASS_StudentSubject_Map_View': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubject_Map_View',
      'ASS_StudentSubject_Map_Del': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubject_Map_Del',
      'ASS_StudentSubjectList_Get': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubjectList_Get',
      'ASS_StudentSubjectList_Del': environment.basePath + environment.basePort + 'api/School/ASS_StudentSubjectList_Del',


      //Assessment Configuration
      'AssessmentForm_Config_Search': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Search',
      'AssessmentForm_Config_Configure': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Configure',
      'AssessmentForm_Config_Get': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Get',
      'AssessmentForm_Config_Ins': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Ins',
      'AssessmentForm_Config_Classes_Get': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Classes_Get',
      'AssessmentForm_Config_Schools_Get': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Schools_Get',
      'AssessmentForm_Config_Upd': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Upd',
      'AssessmentForm_Config_Del': environment.basePath + environment.basePort + 'api/Assessment/AssessmentForm_Config_Del',

      //    'ADD_ASS_QuestionSetup_InsUpd': environment.basePath + environment.basePort + 'api/Assessment/ADD_ASS_QuestionSetup_InsUpd',

      //Block & Unblock
      'ASS_StudentBlockUnblock_Search': environment.basePath + environment.basePort + 'api/School/ASS_StudentBlockUnblock_Search',
      'Update_ASS_StudentBlockUnblock_Upd': environment.basePath + environment.basePort + 'api/School/Update_ASS_StudentBlockUnblock_Upd',

      //class-teacher Mapp 

      'teacherclassMap_delete': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClass_Map_Del',
      'teacherclassMap_ADD': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClassMaping_Map_Ins',
      'ADD_ASS_TeacherClassMaping_Map_Upd': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClassMaping_Map_Remove',
      'teacherclassMap_search': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClass_Map_Search',
      'teacherclassMap_Get': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClassSubject_Map_Get',
      'ASS_TeacherClassMap_SelForDDL': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClassMap_SelForDDL',
      'ASS_TeacherMap_SelForDDL': environment.basePath + environment.basePort + 'api/School/ASS_TeacherMap_SelForDDL',
      'ASS_TeacherClass_Map_View': environment.basePath + environment.basePort + 'api/School/ASS_TeacherClass_Map_View',

      //Assessment Response  
      'ASS_AssessmentForm_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentForm_Search',
      'ASS_AssessmentForm_Response_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentForm_Response_Search',
      'ASS_AssessmentForm_Response_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/ASS_AssessmentForm_Response_Rpt_PDF_EXCEL',
      'ASS_AssessmentForm_Response_View': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentForm_Response_View',
      'ASS_AssessmentResponse_Summary_Get': environment.basePath + environment.basePort + 'api/Assessment/ASS_AssessmentResponse_Summary_Get',

      //Student Import
      'DownloadStudentImportTemplate': environment.basePath + environment.basePort + 'api/School/DownloadStudentImportTemplate',
      'DownloadTeacherImportTemplate': environment.basePath + environment.basePort + 'api/School/DownloadTeacherImportTemplate',

      'GetOrganizationDetails': environment.basePath + environment.basePort + 'api/Home/GetOrganizationDetails',

      //Student Promotion
      'ASS_StudentPromotion_Search': environment.basePath + environment.basePort + 'api/School/ASS_StudentPromotion_Search',
      'ASS_StudentPromotion_InsUpd': environment.basePath + environment.basePort + 'api/School/ASS_StudentPromotion_Ins',
      'ASS_StudentPromotion_Del': environment.basePath + environment.basePort + 'api/School/ASS_StudentPromotion_Del',

      //Send Credential to Student
      'ResetPasswordStudentSearch': environment.basePath + environment.basePort + 'api/UM/ResetPasswordStudentSearch',
      'StudentResetPasswordSendCredMail': environment.basePath + environment.basePort + 'api/UM/StudentResetPasswordSendCredMail',

      //Teacher Block & Unblock
      'ASS_TeacherBlockUnblock_SEARCH': environment.basePath + environment.basePort + 'api/School/ASS_TeacherBlockUnblock_SEARCH',
      'ASS_TeacherBlockUnblock_UPDATE': environment.basePath + environment.basePort + 'api/School/ASS_TeacherBlockUnblock_UPDATE',

      'ASS_VideoUpload': environment.baseReportPath + environment.baseReportPort + 'api/ASS/ASS_VideoUpload',
      'Uploadvideo': environment.baseReportPath + environment.basePort + 'api/Home/Uploadvideo',
      //Teacher Transfer
      'ASS_TeacherTransfer_Search': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransfer_Search',
      'ASS_TeacherTransfer_Get': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransfer_Get',
      'ASS_TeacherTransfer_InsUpd': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransfer_Ins',
      'ASS_TeacherTransfer_Upd': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransfer_Upd',
      'ASS_TeacherTransfer_Del': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransfer_Del',
      'ASS_TeacherTransferList_Get': environment.basePath + environment.basePort + 'api/School/ASS_TeacherTransferList_Get',
      //dashborad
      'GetDashboardDetails': environment.basePath + environment.basePort + 'api/Home/GetDashboardDetails',
      'STUP_StudentProfileCompletion_Get': environment.basePath + environment.basePort + 'api/STUP/STUP_StudentProfileCompletion_Get',

      //Question Setup
      'ASS_Student_Question_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_Search',
      'ASS_Student_Question_Ins': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_Ins',
      'ASS_DifficultyLevel_SelForDDL': environment.basePath + environment.basePort + 'api/School/ASS_DifficultyLevel_SelForDDL',
      'ASS_topic_SelByIdForDDL': environment.basePath + environment.basePort + 'api/School/ASS_topic_SelByIdForDDL',
      'ASS_Student_Question_Get': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_Get',
      'ASS_Student_Question_Upd': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_Upd',
      'ASS_Student_Question_List': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_List',
      'ASS_Student_Question_Del': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_Del',

      //Question Import
      'ASS_Student_Question_ImportExcel_Ins': environment.basePath + environment.basePort + 'api/Assessment/ASS_Student_Question_ImportExcel_Ins',
      'DownloadStudentQuestionImportTemplate': environment.basePath + environment.basePort + 'api/Assessment/DownloadStudentQuestionImportTemplate',
      'UM_User_ForgotUserName': environment.basePath + environment.basePort + 'api/Home/UM_User_ForgotUserName',

      'ASS_Topic_Trn_Search': environment.basePath + environment.basePort + 'api/School/ASS_Topic_Trn_Search',
      'ASS_Topic_Trn_Ins': environment.basePath + environment.basePort + 'api/School/ASS_Topic_Trn_Ins',
      'ASS_Topic_Trn_Get': environment.basePath + environment.basePort + 'api/School/ASS_Topic_Trn_Get',
      'ASS_Topic_Trn_Del': environment.basePath + environment.basePort + 'api/School/ASS_Topic_Trn_Del',

      // CommonNotificationEntry
      'ASS_CommonNotificationEntry_Search': environment.basePath + environment.basePort + 'api/ACM/ASS_CommonNotificationEntry_Search',
      'ASS_CommonNotificationEntry_Ins': environment.basePath + environment.basePort + 'api/ACM/ASS_CommonNotificationEntry_Ins',
      'ASS_CommonNotificationEntry_Upd': environment.basePath + environment.basePort + 'api/ACM/ASS_CommonNotificationEntry_Upd',
      'ASS_CommonNotificationEntry_Get': environment.basePath + environment.basePort + 'api/ACM/ASS_CommonNotificationEntry_Get',
      'ASS_CommonNotificationEntry_Del': environment.basePath + environment.basePort + 'api/ACM/ASS_CommonNotificationEntry_Del',
      'ACM_ViewNotification_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_ViewNotification_Search',

      // Approve teacher Questions    
      'ASS_Question_Status_Search': environment.basePath + environment.basePort + 'api/Assessment/ASS_Question_Status_Search',
      'ASS_Teacher_Question_Ins': environment.basePath + environment.basePort + 'api/Assessment/ASS_Teacher_Question_Ins',
      'ASS_Question_Status_Upd': environment.basePath + environment.basePort + 'api/Assessment/ASS_Question_Status_Upd',
      'ASS_Teacher_Question_Upd': environment.basePath + environment.basePort + 'api/Assessment/ASS_Teacher_Question_Upd',
      'ASS_Teacher_Question_Get': environment.basePath + environment.basePort + 'api/Assessment/ASS_Teacher_Question_Get',
      'ASS_Teacher_Question_Del': environment.basePath + environment.basePort + 'api/Assessment/ASS_Teacher_Question_Del',
      'ASS_Teacher_SelByIdForDDL': environment.basePath + environment.basePort + 'api/School/ASS_Teacher_SelForDDL',

      // CommonNotificationEntry
      'ACM_SMSTemplate_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_SMSTemplate_Search',
      'ACM_SMSTemplate_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_SMSTemplate_Get',
      'ACM_SMSTemplate_Del': environment.basePath + environment.basePort + 'api/ACM/ACM_SMSTemplate_Del',
      'ACM_SMSTemplate_Ins': environment.basePath + environment.basePort + 'api/ACM/ACM_SMSTemplate_Ins',
      'ACM_SMSTemplate_Upd': environment.basePath + environment.basePort + 'api/ACM/ACM_SMSTemplate_Upd',
      //ddl
      'ASS_Chapter_SelForIdDDl': environment.basePath + environment.basePort + 'api/ASS/ASS_Chapter_SelForIdDDl',
      'ASS_Subject_SelForIdDDl': environment.basePath + environment.basePort + 'api/ASS/ASS_Subject_SelForIdDDl',

      //List of School
      'UM_School_List_Search': environment.basePath + environment.basePort + 'api/School/UM_School_List_Search',
      'UM_School_List_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_School_List_Rpt_PDF_EXCEL',

      'ASS_StudentTest_Config_Get': environment.basePath + environment.basePort + 'api/ASS/ASS_StudentTest_Config_Get',
      'ASS_StudentTest_Config_InsUpd': environment.basePath + environment.basePort + 'api/ASS/ASS_StudentTest_Config_InsUpd',
      'ASS_StudentTest_Config_Search': environment.basePath + environment.basePort + 'api/ASS/ASS_StudentTest_Config_Search',
      'ASS_StudentTest_ConfigDel': environment.basePath + environment.basePort + 'api/ASS/ASS_StudentTest_ConfigDel',

      //List of Question
      'UM_Question_List_Search': environment.basePath + environment.basePort + 'api/School/UM_Question_List_Search',
      'UM_Question_List_Search_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_Question_List_Search_Rpt_PDF_EXCEL',

      // Dashboard
      'ASS_StudentAssessmentDetails_Get': environment.basePath + environment.basePort + 'api/Home/ASS_StudentAssessmentDetails_Get',
      'ASS_StudentAssignmentDetails_Get': environment.basePath + environment.basePort + 'api/Home/ASS_StudentAssignmentDetails_Get',

      // Certificate set up 

      'Certtificate_Setup_Ins': environment.basePath + environment.basePort + 'api/ACM/ACM_CertificateSetup_InsUpd',
      'Get_Certificate_Setup': environment.basePath + environment.basePort + 'api/ACM/CertificateGet',
      'CertificateDelete': environment.basePath + environment.basePort + 'api/ACM/ACM_CertificateDelete',
      //Profile information Api
      'Profile_User_Get': environment.basePath + environment.basePort + 'api/UM/UM_Profile_User_Get',
      'Profile_User_Upd': environment.basePath + environment.basePort + 'api/UM/UM_Profile_User_Upd',

    }
  }


}

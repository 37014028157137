<div class="row">
    <div class="col-md-6">
        <input type="file" multiple (change)="handleUpload($event)">
    </div>
    <ul *ngFor="let file of uploadedFile">
        <li>{{file}}</li>
        <!-- <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>   -->
    </ul>
</div>


<mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>


<!-- <button class="btn btn-success" (click)="Showprogressbar()">Show Progress Bar</button> -->


<!-- <mat-progress-bar class="green-progress" mode="indeterminate" [value]="progressbarVal"></mat-progress-bar> -->
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const router = inject(Router);

  let booleanValue = inject(AuthServiceService).isLoggedIn();

  if (booleanValue) {
    return true
  } else {
    router.navigate(['/account']);
    return false
  }
  // return true
  // const user = JSON.parse(sessionStorage.getItem('userData')!);
  // if (user?.isfirstlogin) {
  //   router.navigate(['/account']);
  //   return false;
  // } else if (user && user.authToken && user.active && !user.errorMessage) {
  //   return true;
  // } else {
  //   router.navigate(['/account']);
  //   return false;
  // }




}

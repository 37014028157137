import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment.development"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) {

  }

  ngOnInit(): void {
    // this.getBaseURL()
  }

  getBaseURL() {
    console.log(environment.basePath);
    this.http.get('assets/api_config.json').subscribe(res => {
      console.log("df", res);
      environment.basePath = res['basePath'];
      environment.basePort = res['basePort'];
      environment.baseReportPath = res['baseReportPath'];
      environment.baseReportPort = res['baseReportPort'];
      // urlForTeacher= 'https://gnn.shikshadaily.com/Teacher/';
      // urlForStudent ='https://gnn.shikshadaily.com/Student/';
    })
    setTimeout((ele: any) => {
      console.log(environment.basePath);
    }, 200)
  }

}
